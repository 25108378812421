import React from "react";

import { Form } from "../../components/Form/Form.tsx";

import "../../../../assets/styles/layouts/_container-form.scss";
import { useData } from "../../utils/useData.tsx";

export const Post = () => {
  const { dataAll } = useData("chapters");
  return (
    <div className="container-form">
      <Form
        title="Adicionar uma nova página"
        type="page"
        referenceValue="Número do capítulo"
        currentValue="Número da página"
        textTitle="Texto/Conteúdo"
        data={dataAll}
      />
    </div>
  );
};
