import React from "react";

import MultilineTextFields from "../InpuText/InputText.tsx";

import "./Form.scss";
import "../../../../assets/styles/layouts/_container-form.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PagePost } from "../../types/index.ts";

export const Form = ({
  title,
  type,
  referenceValue,
  currentValue,
  textTitle,
  data,
}: PagePost) => {
  return (
    <div className="form">
      <div className="back-page" onClick={() => window.history.back()}>
        <ArrowBackIcon />
      </div>
      <h1>{title}</h1>
      <MultilineTextFields
        type={type}
        referenceValue={referenceValue}
        currentValue={currentValue}
        textTitle={textTitle}
        data={data}
      />
    </div>
  );
};
