import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: process.env.REACT_APP_HOST,
});

export const fetchData = async (name: string, password: string) => {
  try {
    const response = await api.post(`auth/login`, {
      login: name,
      password: password,
    });

    const token = response.data;

    Cookies.set("authToken", token, { secure: false, sameSite: "Lax" });
  } catch (err) {
    throw (
      err?.message ||
      err.response.message ||
      err?.reponse?.data.error ||
      "An unknown error occurred"
    );
  }
};

api.interceptors.response.use(
  (config) => {
    const token = Cookies.get("authToken");
    if (token) config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/*
        <--          Fetch para Criar um novo item             -->

*/

export const fetchDataNewItem = async (endPoint: string, object: Object) => {
  try {
    const response = await api.post(`${endPoint}`, object, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`,
      },
    });
    return response.status;
  } catch (err) {
    throw (
      err?.message ||
      err.response.message ||
      err?.reponse?.data.error ||
      "An unknown error occurred"
    );
  }
};

/*
        <--          Fetch para editar um item             -->

*/

export const fetchDataEdit = async (
  endPoint: string,
  Id: number,
  Object: Object
) => {
  try {
    const response = await api.put(`${endPoint}${Id}`, Object, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`,
      },
    });

    return response.status;
  } catch (err) {
    throw (
      err?.message ||
      err.response.message ||
      err?.reponse?.data.error ||
      "An unknown error occurred"
    );
  }
};

/*
        <--          Fetch para deletar um item             -->

*/
export const fetchDataDelete = async (endPoint: string, Id: number) => {
  try {
    const response = await api.delete(`${endPoint}${Id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`,
      },
    });

    return response.status;
  } catch (err) {
    throw (
      err?.message ||
      err.response.message ||
      err?.reponse?.data.error ||
      "An unknown error occurred"
    );
  }
};

export const fetchDataDeleteImg = async (
  endPoint: string,
  Id: number,
  img: string
) => {
  try {
    const response = await api.delete(`${endPoint}${Id}/${img}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`,
      },
    });

    return response.status;
  } catch (err) {
    throw (
      err?.message ||
      err.response.message ||
      err?.reponse?.data.error ||
      "An unknown error occurred"
    );
  }
};

export const Logout = () => {
  Cookies.remove("authToken");
  window.location.reload();
};
