import React from "react";

import { Form } from "../../components/Form/Form.tsx";

import "../../../../assets/styles/layouts/_container-form.scss";
import { useData } from "../../utils/useData.tsx";
export const PostChapter = () => {
  const { dataAll } = useData("book");

  return (
    <div className="container-form">
      <Form
        title="Adicionar um novo capítulo"
        type="chapter"
        referenceValue="Número do livro"
        currentValue="Número do capítulo"
        textTitle="Título"
        data={dataAll && dataAll}
      />
    </div>
  );
};
