import * as React from "react";

import SimpleBackdrop from "../Alert/Background.tsx";

import "./InputText.scss";
import { fetchDataNewItem } from "../../../../services/AdminService.ts";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Alert,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  SnackbarCloseReason,
} from "@mui/material";
import { PropsInput } from "../../types/index.ts";
import { useValidFile } from "../../utils/useValidFile.tsx";
import CloseIcon from "@mui/icons-material/Close";

export default function MultilineTextFields({
  type,
  referenceValue,
  currentValue,
  textTitle,
  data,
}: PropsInput) {
  const [reference, setReference] = React.useState<number | string>(0);
  const [current, setCurrent] = React.useState(0);
  const [content, setContent] = React.useState("");
  const [alt, setAlt] = React.useState("");
  const { file, onlyImage, handleFileChange } = useValidFile();

  const [alert, setAlert] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (type === "page") {
      const formData = new FormData();
      formData.append("chapter", reference.toString());
      formData.append("number", current.toString());
      formData.append("file", file);
      formData.append("alt", alt);
      formData.append("content", content);

      handleTryCath("pages", formData);
    } else {
      const objectData = {
        book: reference,
        title: content,
        number: current,
      };
      handleTryCath("chapters", objectData);
    }
  };

  const handleTryCath = async (endPoint: string, objectData: Object) => {
    try {
      await fetchDataNewItem(endPoint, objectData);
      setAlert(true);
    } catch (err) {
      setError(
        err?.data?.message ||
          err?.message ||
          err?.response?.data ||
          err ||
          "Erro desconhecido!"
      );
    }
  };
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setError("");
  };
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <div className="add">
        <div className="add-div">
          <FormControl sx={{ m: 1, width: "25ch" }}>
            <InputLabel id="demo-simple-select-helper-label">
              {referenceValue}
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={reference}
              label={referenceValue}
              onChange={(e) => setReference(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {data &&
                [...data].map((item) => (
                  <MenuItem
                    value={
                      referenceValue !== "Número do capítulo"
                        ? item?.id_book
                        : item?.id_chapter
                    }
                  >
                    {item?.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            id="outlined-textarea"
            label={currentValue}
            placeholder={currentValue}
            type="number"
            onChange={(e) => setCurrent(parseInt(e.target.value))}
          />
        </div>
        {type === "page" && (
          <div className="input-page">
            <div className="input-file">
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e)}
              />
            </div>

            <TextField
              id="outlined-textarea"
              label={"Descrição da imagem"}
              placeholder={"Descrição da imagem"}
              type="text"
              onChange={(e) => setAlt(e.target.value)}
            />
          </div>
        )}
        <div className="text-field">
          <TextField
            id="outlined-multiline-static"
            label={textTitle}
            multiline
            rows={4}
            onChange={(e) => setContent(e.target.value)}
          />
        </div>
        <div className="alert">{alert && <SimpleBackdrop />}</div>
      </div>
      <button className="button-form" onClick={(e) => handleClick(e)}>
        Enviar
      </button>
      {onlyImage && (
        <div className="error">
          <Alert
            severity="error"
            sx={{
              position: "fixed",
              bottom: "5px",
              left: "43%",
              color: "black",
            }}
          >
            Apenas imagens
          </Alert>
        </div>
      )}
      {error && (
        <div className="alert-error">
          <Snackbar
            open={error !== null}
            message={error}
            sx={{ border: "none" }}
            autoHideDuration={6000}
            onClose={handleClose}
            action={action}
          />
        </div>
      )}
    </Box>
  );
}
