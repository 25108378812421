import React, { useEffect, useState } from "react";

import { useData } from "../../utils/useData.tsx";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  Box,
  Button,
  IconButton,
  Snackbar,
  SnackbarCloseReason,
  TextField,
} from "@mui/material";

import "../../../../assets/styles/layouts/_container-form.scss";
import "../../../../assets/styles/components/_buttonForm.scss";

import { fetchDataEdit } from "../../../../services/AdminService.ts";
import SimpleBackdrop from "../../components/Alert/Background.tsx";
import { BookEdit } from "../../types/index.ts";
import CloseIcon from "@mui/icons-material/Close";

export const EditChapter = () => {
  const { dataAll } = useData("book");

  const [book, setBook] = useState(0);
  const [current, setCurrent] = useState(0);
  const [title, setTitle] = useState("");

  const [edit, setEdit] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    dataAll.forEach((item: BookEdit) => {
      item.chapters.forEach((elem) => {
        setBook(elem.id_book);
        setTitle(elem.title);
        setCurrent(elem.number);
      });
    });
  }, [dataAll]);

  async function handleClick(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) {
    e.preventDefault();

    const objectData = {
      book,
      title,
      number: current,
    };

    try {
      await fetchDataEdit("chapters/edit/", id, objectData);
      setEdit(true);
    } catch (error) {
      setError(error || "Erro desconhecido!");
    }
  }

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setError("");
  };
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="container-form edit">
      <div className="form-item">
        <div className="form-item-header">
          <div onClick={() => window.history.back()}>
            <ArrowBackIcon />
          </div>
          <h1>Editar Capítulos</h1>{" "}
        </div>

        <div className="container-itens-edit">
          {dataAll.length > 0 &&
            dataAll.map((item: BookEdit) => (
              <div className="itens-render">
                <h2>Livro: {item.title}</h2>
                <p>Capítulos: </p>
                {item.chapters.map((element) => (
                  <Accordion
                    className=" summary-item"
                    key={element.id_chapter}
                    sx={{ backgroundColor: "#ffffffe3" }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <EditIcon className="icone" color="primary" />
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="summary-title"
                      key={element.id_chapter}
                    >
                      <p>{element.title}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box>
                        <div className="edit-div">
                          <TextField
                            id="outlined-textarea"
                            label={"Número do Livro"}
                            type="number"
                            value={book}
                            onChange={(e) => setBook(parseInt(e.target.value))}
                            sx={{
                              border:
                                "1px solid rgba(255, 255, 255, 0.3607843137)",
                              marginRight: "10px",
                            }}
                          />
                          <TextField
                            id="outlined-textarea"
                            label={"Número do capítulo"}
                            type="number"
                            sx={{
                              border:
                                "1px solid rgba(255, 255, 255, 0.3607843137)",
                            }}
                            value={current}
                            onChange={(e) =>
                              setCurrent(parseInt(e.target.value))
                            }
                          />
                        </div>
                        <div className="text-field">
                          <TextField
                            id="outlined-multiline-static"
                            label={"Título"}
                            multiline
                            rows={7}
                            type="text"
                            sx={{
                              border:
                                "1px solid rgba(255, 255, 255, 0.3607843137)",
                              marginTop: "10px",
                              marginRight: "5px",
                              width: "-webkit-fill-available",
                            }}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                        <button
                          className="button-form"
                          onClick={(e) => handleClick(e, element.id_chapter)}
                        >
                          Enviar
                        </button>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            ))}
        </div>
      </div>
      {edit && <SimpleBackdrop />}
      {error && (
        <Snackbar
          open={error != null}
          message={error}
          autoHideDuration={6000}
          onClose={handleClose}
          action={action}
        />
      )}
    </div>
  );
};
