import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const urlBase = process.env.REACT_APP_HOST;

const fetchData = async ({ queryKey }: { queryKey: [string, string] }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, endPoint] = queryKey;

  try {
    const response = await axios.get(`${urlBase}/${endPoint}`);

    return response?.data;
  } catch (error) {
    throw error.message || error.response.message || error.response.data;
  }
};

export function useBookService(endPoint: string) {
  const query = useQuery({
    queryFn: fetchData,
    queryKey: ["book-news", endPoint],
  });

  return query;
}
